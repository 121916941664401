
<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>

        <v-row class="ma-2">
            <v-col cols="12" sm="3" md="3">
                <v-select v-model="selected_ay" label="Academic Year" :items="ay_list" item-text="ay" item-value="id"
                    outlined></v-select>
            </v-col>
            <v-col cols="12" sm="3" md="3">
                <v-select v-model="selected_org" label="Organization" :items="org_list" item-text="name" item-value="id"
                    outlined></v-select>
            </v-col>
            <!-- <v-col cols="12" sm="3" md="3">
                <v-skeleton-loader  v-if="loading_select" v-bind="attrs" type="list-item-two-line"></v-skeleton-loader>
                <v-autocomplete v-else v-model="selected_company" label="Company" :items="company_list" item-text="name" item-value="id"
                    outlined></v-autocomplete>
            </v-col> -->
            <v-col cols="12" sm="3" md="3">
                <v-btn class="primary mt-2" @click="fetchCurrentDashboardData"> Fetch </v-btn>
            </v-col>
        </v-row>
        <v-skeleton-loader class="ma-3 pa-2" v-if="loading" v-bind="attrs" type="list-item-three-line"></v-skeleton-loader>
        <div v-else>

            <div class="ma-3" v-if="Array.isArray(company_wise_dashbord_details) &&
                company_wise_dashbord_details.length">

                <div v-for="(item, index2) in company_wise_dashbord_details" :key="index2">
                    <fieldset style="border-color: white;" class="pa-3">
                        <legend>{{ item[0] }}</legend>

                        <v-row>
                            <v-col cols="12" sm="3" md="3">


                                <barchart :data="item[1]" :key="index2" :options="chartOptions" />

                            </v-col>
                            <v-col cols="12" sm="3" md="3">


                                <linechart :data="item[1]" :key="index2" :options="chartOptions" />

                            </v-col>
                            <v-col cols="12" sm="3" md="3">


                                <v-simple-table>
                                    <thead>
                                        <tr>
                                            <th>Program</th>
                                            <th>Placed Student</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item1, index3) in item[3]" :key="index3">
                                            <td>{{ item1[0].abbrivation }}</td>
                                            <td>{{ item1[1] }}</td>
                                        </tr>
                                        <tr >
                                            <td>Total</td>
                                            <td>{{item[2]}}</td>
                                        </tr>
                                    </tbody>
                                </v-simple-table>

                            </v-col>


                        </v-row>


                    </fieldset>
                </div>
            </div>



        </div>

    </div>
</template>
<script>
import linechart from '../TPO/chart/line_chart.vue'
import barchart from '../TPO/chart/bar_chart.vue'
import axios from "axios";
export default {
    components: { barchart, linechart },
    data: () => ({
        rerender1: 0,
        selected_ay: "",
        loading: false,
        snackbar_msg: "",
        color: "",
        snackbar: false,
        selected_org: "",
        selected_company: "",
        loading_select: false,
        company_list: [],
        rerender: 0,
        company_wise_dashbord_details: [],
        chartOptions: {
            responsive: true,
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true
                    },
                }]
            },
        },
    }),
    props: ['ay_list', 'current_ay', 'org_list', 'current_org'],
    mounted() {

        this.selected_ay = this.current_ay;
        this.selected_org = this.current_org.id;
        // this.init();
    },
    methods: {
        init() {
            this.loading_select = true;
            var params = {
                selected_ay: this.selected_ay,
                selected_org: this.selected_org
            };
            axios.post("/Dashboard/getCompnayListFromAY", params).then((res) => {
                if (res.data.msg == "200") {
                    this.company_list = res.data.company_list;
                    this.loading_select = false;
                } else {
                    this.showSnackbar("red", res.data.msg);
                    this.loading_select = false;
                }
            });
        },
        fetchCurrentDashboardData() {
            // if(!this.selected_company||this.selected_company==''||this.selected_company==null||this.selected_company==undefined||this.selected_company=='undefined')
            // {
            //     this.showSnackbar("red", "Please Select Company");
            //     return;
            // }
            this.loading = true;
            var params = {
                selected_ay: this.selected_ay,
                selected_org: this.selected_org,
                // selected_company:this.selected_company,
            };

            axios.post("/Dashboard/get_company_wise_dashbord_details", params).then((res) => {
                if (res.data.msg == "200") {
                    this.company_wise_dashbord_details = res.data.company_wise_dashbord_details;
                    var main_array = []
                    if ((this.company_wise_dashbord_details != 'undefined' || this.company_wise_dashbord_details.length > 0)) {

                        for (var x in this.company_wise_dashbord_details) {
                            var temp_array = [];
                            var label = [];
                            var company_data = [];
                            var total = 0
                            temp_array.push(this.company_wise_dashbord_details[x].code);
                            console.log(this.company_wise_dashbord_details[x].data);
                            for (var j in this.company_wise_dashbord_details[x].data) {

                                label.push(this.company_wise_dashbord_details[x].data[j][0].abbrivation);
                                company_data.push(this.company_wise_dashbord_details[x].data[j][1]);
                                total += this.company_wise_dashbord_details[x].data[j][1];

                            }

                            temp_array.push({ "labels": label, "datasets": [{ "data": company_data, "label": "Company Wise", "backgroundColor": 'skyblue', }] });


                            temp_array.push(total);
                            temp_array.push(this.company_wise_dashbord_details[x].data);
                            main_array.push(temp_array);
                        }


                    }
                    this.company_wise_dashbord_details = main_array;
                    this.loading = false;
                } else {
                    this.showSnackbar("red", res.data.msg);
                    this.loading = false;
                }
            });
        },
        showSnackbar(clr, msg) {
            this.snackbar = true;
            this.color = clr;
            this.snackbar_msg = msg;
        },
    }
};
</script>
<style scoped>
legend {
    background-color: gray;
    color: white;
    padding: 5px 10px;
}
</style>
